import React, { useEffect } from 'react';

function ViewFiles() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);
  
  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="file-list">
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-ai"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-ai"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-csv"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-js"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-yml"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-xlsx"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-sql"></i>
              File name here
            </a>
          </div>
          <div className="file">
            <a href="/">
              <i class="bi bi-filetype-py"></i>
              File name here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewFiles;