import React, { useEffect } from 'react';
import Disclaimer from '../../components/ChatBox/Disclaimer';
import UploadFile from '../../components/ChatBox/UploadFile';
import VoiceDictation from '../../components/ChatBox/VoiceDictation';
import ChatInput from '../../components/ChatBox/ChatInput';

function Chat() {
  useEffect(() => {
    const chatView = document.querySelector('.main');
    if (chatView) {
      chatView.classList.add('has-chat-header');
    }

    // Refactor this
    const hideAltOptions = document.querySelector('.project-options');
    if (hideAltOptions) {
      hideAltOptions.classList.add('d-none');
    }

  }, []);

  return (
    <>
    <div className="view-chat-convo-container">
      <div className="view-chat-convo-container-inner">
        <div className="chat-container">
          <div className="user-message">
            <div className="row">
              <div className="col-1">
                <div className="user-initials">
                  <span>J</span>
                </div>
              </div>
              <div className="col-11">
                <p>This is an example of the user's message?</p>
              </div>
            </div>
          </div>
          <div className="chat-ai-message">
            <div className="row">
              <div className="col-1">
                <img src="assets/images/ai-soul.gif" alt="logo mark" className="logo-mark"/>
              </div>
              <div className="col-11">
                <h2>Title Header h2</h2>
                <h3>Title Header h3</h3>
                <h4>Title Header h4</h4>
                <h5>Title Header h5</h5>
                <h6>Title Header h5</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <span className="highlight">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>
                <ul>
                  <li>List item example</li>
                  <li>
                    List item example
                    <ul>
                      <li>List item example</li>
                      <li>List item example</li>
                    </ul>
                  </li>
                  <li>List item example</li>
                </ul>
                <ol>
                  <li>List item example</li>
                  <li>
                    List item example
                    <ul>
                      <li>List item example</li>
                      <li>List item example</li>
                    </ul>
                  </li>
                  <li>List item example</li>
                </ol>
                <code>
                  <div className="code-header">
                    <div className="row">
                      <div className="col-6 valign">
                        <span>python</span>
                      </div>
                      <div className="col-6 text-end">
                        <a href="#" className="btn">
                          <i className="bi bi-clipboard"></i> Copy
                        </a>
                      </div>
                    </div>
                  </div>
                  <pre>&gt;&gt;&gt; print("Hello, World!") Hello, World!</pre>
                </code>
                <code>
                  <div className="code-header">
                    <div className="row">
                      <div className="col-6 valign">
                        <span>Work Result</span>
                      </div>
                      <div className="col-6 text-end">
                        <a href="#" className="btn">
                          <i className="bi bi-clipboard"></i> Copy
                        </a>
                      </div>
                    </div>
                  </div>
                  <pre>Hello, World!</pre>
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="view-chat-box-container">
      <div className="view-chat-box">
        <UploadFile/>
        <VoiceDictation/>
        <ChatInput/>
      </div>
      <Disclaimer/>
    </div>
    </>
  );
}

export default Chat;
