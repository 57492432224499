import React from 'react';
import { useRoutes } from 'hookrouter';
import Chat from './views/Chat/Chat';
import Dashboard from './views/Dashboard';
import Search from './views/Search/Search';
import Welcome from './views/Chats/Welcome';
import Favorites from './views/Chats/Favorites/Favorites';
import Archived from './views/Chats/Archived/Archived';
import History from './views/Chats/History/History';
import Trashed from './views/Chats/Trashed/Trashed';
import Folders from './views/Chats/Folders/Folders';
import Folder from './views/Chats/Folders/Folder';
import Support from './views/Support/Support';
import ViewFiles from './views/ViewFiles/ViewFiles';

import Projects from './views/Projects/Projects';
import Project from './views/Projects/Project/Project';
import ProjectChats from './views/Projects/Project/ProjectItems/ProjectChats';
import ProjectImages from './views/Projects/Project/ProjectItems/ProjectImages';
import ProjectGraphs from './views/Projects/Project/ProjectItems/ProjectGraphs';
import ProjectReports from './views/Projects/Project/ProjectItems/ProjectReports';
import BillingHelp from './views/Subscription/BillingHelp';
import ProFeatures from './views/Subscription/ProFeatures';
import ProUpgrade from './views/Subscription/ProUpgrade';

import Login from './views/Login/Login';
import Signup from './views/Signup/Signup';

const Routes = () => {
  const routes = {
    '/login': () => <Login />,
    '/signup': () => <Signup />,
    '/': () => <Dashboard />,
    '/chat': () => <Chat />,
    '/search': () => <Search />,
    '/welcome': () => <Welcome />,
    '/favorites': () => <Favorites />,
    '/archived': () => <Archived />,
    '/history': () => <History />,
    '/trashed': () => <Trashed />,
    '/folders': () => <Folders />,
    '/folder': () => <Folder />,
    '/projects': () => <Projects />,
    '/project': () => <Project />,
    '/project/chats': () => <ProjectChats />,
    '/project/images': () => <ProjectImages />,
    '/project/graphs': () => <ProjectGraphs />,
    '/project/reports': () => <ProjectReports />,
    '/support': () => <Support />,
    '/billing-help': () => <BillingHelp />,
    '/pro-features': () => <ProFeatures />,
    '/pro-upgrade': () => <ProUpgrade />,
    '/files': () => <ViewFiles />,
  };

  return useRoutes(routes);
};

export default Routes;