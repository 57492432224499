import React from 'react'

function ChatHeader() {
  
  function toggleSidebar(button) {
    // Toggle the options sidebar view
    var biIcons = document.querySelectorAll('.toggle-sidebar');
    var view = document.querySelector('.view');
    var viewSide = document.getElementById('viewSide');
    
    // Check if the clicked button already has the 'rotate' class
    var alreadyRotated = button.classList.contains('rotate');
    
    // Toggle the class 'rotate-45' on the clicked button
    button.classList.toggle('rotate');
    view.classList.toggle('p-right');
    
    // Toggle the class 'hide' on the .view-side element
    viewSide.classList.toggle('show-options');
    
    // Disable other buttons if the clicked button has 'rotate' class
    if (alreadyRotated) {
      biIcons.forEach(function(icon) {
        icon.disabled = false;
      });
    } else {
      biIcons.forEach(function(icon) {
        if (icon !== button) {
          icon.disabled = true;
        }
      });
    }
  }

  return (
    <div className="chat-header">
      <div className="view-title valign">
        <div className="view-title-container">
          <input type="text" placeholder="Generated Chat Title" aria-label="Chat Title"/>
          <div className="view-title-rename-options">
            <i className="bi bi-check2"></i>
            <i className="bi bi-x"></i>
          </div>
        </div>
      </div>
      <div className="view-header-nav valign text-end">
        <ul>
          <li className="toggle-sidebar toggle-chat-options" onClick={(event) => toggleSidebar(event.target)} aria-label="Toggle Sidebar">
            <i className="bi bi-three-dots" title="View Chat Options"></i>
          </li>
          <li className="new-chat">
            <a href="/" aria-label="Start a New Chat">
              <span className="visually-hidden">Start a New Chat</span>
              <i className="bi bi-pencil-square" title="Start a New Chat" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ChatHeader