import React from 'react'

function ChatInput(props) {
  return (
    <>
      <input id="textInput" type="text" placeholder={props.placeholder || "Talk to Chatbot..."}/>
      <i className="bi bi-upload send-message" title="Send Message"></i>
    </>
  )
}

export default ChatInput