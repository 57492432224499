import React from 'react'
import ChatFolderList from '../ChatFolderList/ChatFolderList'

function ChatOptions() {
  return (
   <div className="chat-options">
    <div className="view-side-header">
      <h2>Chat Options</h2>
    </div>
    <div className="view-side-content">
      <div className="view-side-content-actions">
        <ul>
          <li className="vs-01">
            <i className="bi bi-pencil-fill"></i> Rename Chat
          </li>
          <li className="vs-02">
            <i className="bi bi-clipboard-fill"></i> Copy Chat
          </li>
          <li>
            <i className="bi bi-arrow-clockwise"></i> Regenerate
          </li>
          <li>
            <i className="bi bi-send-fill"></i> Share Chat
          </li>
          <li>
            <i className="bi bi-file-earmark-arrow-down-fill"></i> Export to PDF
          </li>
          <li>
            <i className="bi bi-heart-fill"></i> Move to Favorites
          </li>
          <li>
            <i className="bi bi-archive-fill"></i> Move to Archives
          </li>
          <li>
            <i className="bi bi-trash2-fill"></i> Delete Chat
          </li>
        </ul>
      </div>
      <div className="accordion" id="accordionExample2">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">
              Add to Project
            </button>
          </h2>
          <div id="collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionExample2">
            <div className="accordion-body">
              <ul className="sidebar-list-folders">
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> This shows when My Projects is enabled 
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> Project Name Title Here Long Format
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> Project Name Title
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> This shows when My Projects is enabled
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> Project Name Title Here Long Format
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-database-fill"></i> Project Name Title
                  </a>
                </li>
              </ul>
              <ul className="chat-list-actions">
                <li>
                  <a href="#">
                    <i className="bi bi-plus-circle-fill"></i> Add New Project
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3">
              Add to Folder
            </button>
          </h2>
          <div id="collapseTwo3" className="accordion-collapse collapse" data-bs-parent="#accordionExample2">
            <div className="accordion-body">
              <ChatFolderList/>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default ChatOptions