import React from 'react'

function Disclaimer() {
  return (
    <h6>
      Some disclaimer here. Need help? Start a <a href="/support">support chat</a>.
    </h6>
  )
}

export default Disclaimer