import React, { useEffect } from 'react';

function Welcome() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);
  
  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Welcome to Chatbot AI
          </h1>
          <h2>
            Here is a quick guide on how to get started!
          </h2>
        </div>
        <div className="nomichat-message">
          <div className="video-container">
            <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
              <iframe 
                src="https://player.vimeo.com/video/100902001?h=dcaa89d3e0&title=0&byline=0&portrait=0" 
                style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", border: "0"}} 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div className="welcome-content">
            <h2>
              Section title
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
