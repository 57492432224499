import React, { useEffect } from 'react';

function Trashed() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);

  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Trashed Chats
          </h1>
          <button className="btn-empty-trash">
            Empty Trash Bin
          </button>
        </div>
        <div className="chats-trashed-list">
          {[...Array(7)].map((_, index) => (
          <div className="chats-trashed-item" key={index}>
            <div className="chats-trashed-button">
              <div>
                <button className="toggle-trashed">
                  <i className="bi bi-trash2-fill"></i>
                </button>
              </div>
            </div>
            <div className="chats-trashed-content">
              <a href="#">
                Chat title
              </a>
              <p>
                This is a trashed chat preview. The little trash can toggles the chat item to be untrashed and on page reload / refresh it will be removed from the trash chat list and into the history chat list. Clicking on the title goes to the chat.
              </p>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Trashed