import React from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import ChatHeader from './views/Chat/ChatHeader';
import ChatOptions from './components/ChatOptions/ChatOptions';
import ProjectHeader from './views/Projects/Project/ProjectHeader';
import ProjectOptions from './views/Projects/ProjectOptions/ProjectOptions';
import Routes from './Routes';
import Login from './views/Login/Login';
import Signup from './views/Signup/Signup';

const App = () => {
  const onLoginPage = window.location.pathname === '/login';
  const onSignupPage = window.location.pathname === '/signup';

  return (
    <>
      {onLoginPage && (
        <Login />
      )}

      {onSignupPage && (
        <Signup />
      )}

      {!onLoginPage && !onSignupPage && (
        <div className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 p-relative left-side">
                <Sidebar />
              </div>
              <div className="col-md-10 p-0 p-relative right-side">
                <div className="view">
                  <div className="view-main">
                    <div className="view-header">
                      <ChatHeader />
                      <ProjectHeader />
                    </div>
                    <div className="view-chat">
                      <Routes />
                    </div>
                  </div>
                  <div className="view-side" id="viewSide">
                    <ChatOptions />
                    <ProjectOptions />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
