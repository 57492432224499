import React, { useEffect } from 'react';

function Archived() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);

  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Archived Chats
          </h1>
        </div>
        <div className="chats-archived-list">
          {[...Array(7)].map((_, index) => (
          <div className="chats-archived-item" key={index}>
            <div className="chats-archived-button">
              <div>
                <button className="toggle-archived">
                  <i className="bi bi-archive-fill"></i>
                </button>
              </div>
            </div>
            <div className="chats-archived-content">
              <a href="#">
                Chat title
              </a>
              <p>
                This is an archived chat preview. The little archive box toggles the chat item to be unarchived and on page reload / refresh it will be removed from the archived chat list. Clicking on the title goes to the chat.
              </p>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Archived