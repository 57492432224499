import React from 'react';
import { usePath } from 'hookrouter';

function ChatFolderList() {
  const currentPath = usePath();

  return (
    <>
      <ul className="sidebar-list-folders">
        <li>
          <a href="folder" className={currentPath === '/folder' ? 'active' : ''}>
            <i className="bi bi-folder-fill"></i> Chat Folder Name
          </a>
        </li>
      </ul>
      <ul className="chat-list-actions mt-2">
        <li>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal5">
            <i className="bi bi-folder"></i> Manage Folders
          </a>
        </li>
      </ul>
    </>
  )
}

export default ChatFolderList