import React from 'react'

function ProjectOptions() {
  return (
   <div className="project-options">
    <div className="view-side-header">
      <h2>Project Options</h2>
    </div>
    <div className="view-side-content">
      <div className="view-side-content-actions">
        <ul>
          <li className="vs-01">
            <i className="bi bi-pencil-fill"></i> Rename Project
          </li>
          <li className="vs-02">
          <i className="bi bi-clipboard2-plus-fill"></i> Duplicate Project
          </li>
          <li>
            <i className="bi bi-send-fill"></i> Share Project
          </li>
          <li>
            <i className="bi bi-file-earmark-arrow-down-fill"></i> Export to PDF
          </li>
          <li>
            <i className="bi bi-trash2-fill"></i> Delete Project
          </li>
        </ul>
      </div>
      <div className="accordion" id="accordionExample2">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">
              Project Items
            </button>
          </h2>
          <div id="collapseOne2" className="accordion-collapse collapse" data-bs-parent="#accordionExample2">
            <div className="accordion-body">
              <ul className="sidebar-list-folders">
                <li>
                  <a href="#">
                  <i className="bi bi-box"></i> (21) Chats
                  </a>
                </li>
                <li>
                  <a href="#">
                  <i className="bi bi-box"></i> (36) Images
                  </a>
                </li>
                <li>
                  <a href="#">
                  <i className="bi bi-box"></i> (0) Graphs
                  </a>
                </li>
                <li>
                  <a href="#">
                  <i className="bi bi-box"></i> (0) Reports
                  </a>
                </li>
              </ul>
              <ul className="chat-list-actions">
                <li>
                  <a href="#">
                    <i className="bi bi-plus-circle-fill"></i> Add New Item
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default ProjectOptions