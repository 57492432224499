import React from 'react'
import { usePath } from 'hookrouter';
import ChatFolderList from '../ChatFolderList/ChatFolderList';

function Sidebar() {
  const currentPath = usePath();
  
  function openMenu() {
    var sidebarContents = document.querySelector('.sidebar-contents');
    var menuIcon = document.getElementById('menuIcon');
    
    sidebarContents.classList.toggle('show');
    menuIcon.classList.toggle('bi-list');
    menuIcon.classList.toggle('bi-x');
  }

  return (
    <>
      <div className='d-none'>
        {currentPath}
      </div>
      <div className="sidebar-content">
        <div className="sidebar">
          <div className="sidebar-top">
            <a href="/">
              <img src="http://localhost:3000/assets/images/ai-soul.gif" alt="chatbot" className="orb"/>
              <span>Chatbot AI</span>
            </a>
            <div className="mobile-top" onClick={openMenu}>
              <i id="menuIcon" className="bi bi-list"></i>
            </div>
          </div>
        </div>
        <div className="sidebar-contents">
          <div className="user-welcome">
            <p>
              Welcome, <span>Jon!</span>
            </p>
          </div>
          <div className="sidebar-nav">
            <div className="sidebar-links">
              <ul>
                <li>
                  <a href="/chat" className={currentPath === '/chat' ? 'active' : ''}
                  >
                    <i className="bi bi-chat-left-text"></i> Chat
                  </a>
                </li>
                <li>
                  <a href="/search" className={currentPath === '/search' || currentPath === '/search-results' ? 'active' : ''}
                  >
                    <i className="bi bi-search"></i> Search
                  </a>
                </li>
                <li>
                  <a href="/files" className={currentPath === '/files' ? 'active' : ''}
                  >
                    <i class="bi bi-files"></i> View Files
                  </a>
                </li>
                <li>
                  <a href="/projects" 
                      className={
                        currentPath === '/projects' 
                        || currentPath === '/project'  
                        || currentPath === '/project/chats' 
                        || currentPath === '/project/images'  
                        || currentPath === '/project/graphs'  
                        || currentPath === '/project/reports' 
                      ? 'active' : ''}
                  >
                    <i className="bi bi-database"></i> My Projects <span className="pro">PRO</span>
                  </a>
                </li>
                <li>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                    <i className="bi bi-credit-card"></i> Subscription
                  </a>
                </li>
                <li>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i className="bi bi-gear"></i> Settings
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="sidebar-chat-list">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Chats
                  </button>
                </h2>
                <div id="collapseOne" className={`accordion-collapse collapse ${currentPath !== '/folder' ? 'active show' : ''}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body pb-0">
                    <ul className="sidebar-list-folders">
                      <li>
                        <a href="/welcome" className={currentPath === '/welcome' ? 'active' : ''}>
                          <i className="bi bi-stars"></i> Welcome
                        </a>
                      </li>
                      <li>
                        <a href="/favorites" className={currentPath === '/favorites' ? 'active' : ''}>
                          <i className="bi bi-heart-fill"></i> Favorites
                        </a>
                      </li>
                      <li>
                        <a href="/archived" className={currentPath === '/archived' ? 'active' : ''}>
                          <i className="bi bi-archive-fill"></i> Archived
                        </a>
                      </li>
                      <li>
                        <a href="/history" className={currentPath === '/history' ? 'active' : ''}>
                          <i className="bi bi-clock-history"></i> History
                        </a>
                      </li>
                      <li>
                        <a href="/trashed" className={currentPath === '/trashed' ? 'active' : ''}>
                          <i className="bi bi-trash2-fill"></i> Trash
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Chat Folders
                  </button>
                </h2>
                <div id="collapseTwo" className={`accordion-collapse collapse ${currentPath === '/folder' ? 'active show' : ''}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <ChatFolderList/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar