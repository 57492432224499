import React, { useEffect } from 'react';
import UploadFile from '../components/ChatBox/UploadFile';
import VoiceDictation from '../components/ChatBox/VoiceDictation';
import Disclaimer from '../components/ChatBox/Disclaimer';
import ChatInput from '../components/ChatBox/ChatInput';

function Dashboard() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);

  return (
    <>
    <div className="view-chat-convo-container">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            The Power of Chatbot AI
          </h1>
          <h2>
            Chat with the smartest AI Chatbot
          </h2>
        </div>
        <nav className="dashboard-links">
          <a href="/welcome">
            <span>View the Quick Start Guide</span>
          </a>
          <a href="/">
            <span>Start a New Chat</span>
          </a>
          <a href="/create-project">
            <span>Create a New Project</span>
          </a>
        </nav>
      </div>
    </div>

    <div className="view-chat-box-container">
      <div className="view-chat-box">
        <UploadFile/>
        <VoiceDictation/>
        <ChatInput/>
      </div>
      <Disclaimer/>
    </div>
    </>
  );
}

export default Dashboard;
