import React, { useEffect } from 'react';

function VoiceDictation() {

  function setupVoiceToText() {
    const voiceButton = document.getElementById('voiceButton');
    const textInput = document.getElementById('textInput');
    const sendMessage = document.querySelector('.send-message');

    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;

    recognition.onresult = function (event) {
      const transcript = event.results[0][0].transcript;
      textInput.value = transcript;
    }

    recognition.onerror = function (event) {
      console.error('Speech recognition error:', event.error);
    }

    voiceButton.addEventListener('click', function () {
      recognition.start();
      voiceButton.classList.add('voice-active');
    });

    recognition.onend = function () {
      voiceButton.classList.remove('voice-active');
      updateSendMessageOpacity(); // Update send message opacity when speech recognition ends
    };

    // Event listener to check input field text
    textInput.addEventListener('input', function () {
      updateSendMessageOpacity(); // Update send message opacity when input changes
    });

    function updateSendMessageOpacity() {
      if (textInput.value.trim() !== '') {
        sendMessage.classList.add('active'); // Add active class to send message
      } else {
        sendMessage.classList.remove('active'); // Remove active class from send message
      }
    }
  }

  useEffect(() => {
    // Call the setup function when the component mounts
    setupVoiceToText();
  }, []);

  return (
    <i id="voiceButton" className="bi bi-mic" title="Voice Message"></i>
  )
}

export default VoiceDictation