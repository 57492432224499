import React, { useEffect } from 'react';

function History() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }

    // Refactor this
    const hideAltOptions = document.querySelector('.project-options');
    if (hideAltOptions) {
      hideAltOptions.classList.add('d-none');
    }

    const toggleChatButtons = document.querySelectorAll('.toggle-chat-options');
    const viewSide = document.querySelector('.view-side');

    const toggleButtonClick = function() {
      const chatIcon = this.querySelector('i');
      chatIcon.classList.toggle('bi-three-dots');
      chatIcon.classList.toggle('bi-three-dots-vertical');

      const parentDiv = this.closest('.chats-history-item');
      if (parentDiv) {
        parentDiv.classList.toggle('active-chat-history-item');
        if (parentDiv.classList.contains('inactive-chat-history-item')) {
          this.setAttribute('inactive', 'true');
        } else {
          this.removeAttribute('inactive');
        }
      }

      const allDivs = document.querySelectorAll('.chats-history-item');
      allDivs.forEach(function(div) {
        if (div !== parentDiv) {
          div.classList.toggle('inactive-chat-history-item');
          const buttonInsideDiv = div.querySelector('button.toggle-chat-options');
          if (buttonInsideDiv) {
            buttonInsideDiv.toggleAttribute('disabled');
          }
        }
      });

      viewSide.classList.toggle('chat-show-options');
      this.classList.toggle('active-toggle-btn');
    };

    toggleChatButtons.forEach(function(button) {
      button.addEventListener('click', toggleButtonClick);
    });

    // Cleanup function
    return () => {
      toggleChatButtons.forEach(function(button) {
        button.removeEventListener('click', toggleButtonClick);
      });
    };
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            History Chats
          </h1>
        </div>
        <div className="chats-history-list">
          {[...Array(7)].map((_, index) => (
            <div className="chats-history-item" key={index}>
              <div className="chats-history-button">
                <div>
                  <button className="toggle-chat-options">
                    <i className="bi bi-three-dots"></i>
                  </button>
                </div>
              </div>
              <div className="chats-history-content">
                <a href="#">
                  Chat title
                </a>
                <p>
                  This is a history chat preview. Unfavorited, unarchived, and unmoved chats will go here. The button on the left of the title 
                  shows the chat options where the user can move the chat accordingly.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default History;