import React, { useEffect } from 'react';

function Folder() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }

    // Refactor this
    const hideAltOptions = document.querySelector('.project-options');
    if (hideAltOptions) {
      hideAltOptions.classList.add('d-none');
    }

    const toggleChatButtons = document.querySelectorAll('.toggle-chat-options');
    const viewSide = document.querySelector('.view-side');

    const toggleButtonClick = function() {
      const chatIcon = this.querySelector('i');
      chatIcon.classList.toggle('bi-three-dots');
      chatIcon.classList.toggle('bi-three-dots-vertical');

      const parentDiv = this.closest('.chats-favorites-item');
      if (parentDiv) {
        parentDiv.classList.toggle('active-chat-favorites-item');
        if (parentDiv.classList.contains('inactive-chat-favorites-item')) {
          this.setAttribute('inactive', 'true');
        } else {
          this.removeAttribute('inactive');
        }
      }

      const allDivs = document.querySelectorAll('.chats-favorites-item');
      allDivs.forEach(function(div) {
        if (div !== parentDiv) {
          div.classList.toggle('inactive-chat-favorites-item');
          const buttonInsideDiv = div.querySelector('button.toggle-chat-options');
          if (buttonInsideDiv) {
            buttonInsideDiv.toggleAttribute('disabled');
          }
        }
      });

      viewSide.classList.toggle('chat-show-options');
      this.classList.toggle('active-toggle-btn');
    };

    toggleChatButtons.forEach(function(button) {
      button.addEventListener('click', toggleButtonClick);
    });

    // Cleanup function
    return () => {
      toggleChatButtons.forEach(function(button) {
        button.removeEventListener('click', toggleButtonClick);
      });
    };
  }, []); // Empty dependency array to run only once when the component mounts
  
  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Chat Folder Name
          </h1>
        </div>
        <div className="chats-favorites-list">
          {[...Array(7)].map((_, index) => (
            <div className="chats-favorites-item" key={index}>
              <div className="chats-favorite-button">
                <div>
                  <button className="toggle-chat-options" inactive="true">
                    <i className="bi bi-three-dots"></i>
                  </button>
                </div>
              </div>
              <div className="chats-favorite-content">
                <a href="#">
                  Chat title
                </a>
                <p>
                  This is a folder with chat list preview. Clicking on the title goes to the chat. User has chat options.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Folder; 