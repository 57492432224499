import React from 'react'

function Signup() {
  return (
    <div className="view-login">
      <div className="view-login-container">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-align">
              <div className="login-signup">
                <a href="/">
                  <img src="http://localhost:3000/assets/images/ai-soul.gif" alt="chatbot" className="orb"/>
                  <span>Chatbot AI</span>
                </a>
                <div className="heading">
                  <h1>
                    Create an Account
                  </h1>
                  <p>
                    Enter your email and password to signup.
                  </p>
                  <form className="row needs-validation" novalidate>
                    <div className="col-md-12">
                      <label for="loginUsername" className="form-label">
                        Username
                      </label>
                      <div className="input-group has-validation">
                        <input type="text" className="form-control" id="loginUsername" aria-describedby="inputGroupPrepend" required/>
                        <div className="invalid-feedback">
                          Please enter a username.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label for="loginPassword" className="form-label">
                        Password
                      </label>
                      <div className="input-group has-validation">
                        <input type="text" className="form-control" id="loginPassword" aria-describedby="inputGroupPrepend" required/>
                        <div className="invalid-feedback">
                          Please enter a password.
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <button className="btn btn-primary" type="submit">Signup</button>
                    </div>
                  </form>
                  <h3>
                    Already signed up? <a href="/login">Login to your Account</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="content">
                <img src="http://localhost:3000/assets/images/robot.png" alt="chatbot" className="robot w-75"/>
                <h2>
                  The AI Assistant that easily manages your data. 
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup