import React, { useEffect } from 'react';
import UploadFile from '../../components/ChatBox/UploadFile';
import VoiceDictation from '../../components/ChatBox/VoiceDictation';
import Disclaimer from '../../components/ChatBox/Disclaimer';
import ChatInput from '../../components/ChatBox/ChatInput';

function Support() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []);

  return (
    <>
    <div className="view-chat-convo-container">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Support Chat
          </h1>
          <h2>
            How can we help you?
          </h2>
        </div>
      </div>
    </div>

    <div className="view-chat-box-container">
      <div className="view-chat-box">
        <VoiceDictation/>
        <ChatInput placeholder="Type your message here..." />
      </div>
      <Disclaimer/>
    </div>
    </>
  );
}

export default Support;
