import React, { useEffect, useState } from 'react';

function ToggleButton({ storageKey }) {
  const [isToggled, setIsToggled] = useState(() => {
    // Initialize state from local storage if available, otherwise default to false
    const storedValue = localStorage.getItem(storageKey);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    // Save state to local storage whenever it changes
    localStorage.setItem(storageKey, JSON.stringify(isToggled));
  }, [isToggled, storageKey]);

  const handleClick = () => {
    setIsToggled(prevState => !prevState);
  };

  return (
    <button className={`toggle-pin ${isToggled ? 'toggled' : ''}`} onClick={handleClick}>
      <i className="bi bi-pin-fill"></i>
    </button>
  );
}

function Projects() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }

    // Refactor this
    const hideAltOptions = document.querySelector('.chat-options');
    if (hideAltOptions) {
      hideAltOptions.classList.add('d-none');
    }

    const toggleChatButtons = document.querySelectorAll('.toggle-chat-options');
    const viewSide = document.querySelector('.view-side');

    const toggleButtonClick = function() {
      const chatIcon = this.querySelector('i');
      chatIcon.classList.toggle('bi-three-dots');
      chatIcon.classList.toggle('bi-three-dots-vertical');

      const parentDiv = this.closest('.projects-item');
      if (parentDiv) {
        parentDiv.classList.toggle('active-projects-item');
        if (parentDiv.classList.contains('inactive-projects-item')) {
          this.setAttribute('inactive', 'true');
        } else {
          this.removeAttribute('inactive');
        }
      }

      const allDivs = document.querySelectorAll('.projects-item');
      allDivs.forEach(function(div) {
        if (div !== parentDiv) {
          div.classList.toggle('inactive-projects-item');
          const buttonInsideDiv = div.querySelector('button.toggle-chat-options');
          if (buttonInsideDiv) {
            buttonInsideDiv.toggleAttribute('disabled');
          }
        }
      });

      viewSide.classList.toggle('chat-show-options');
      this.classList.toggle('active-toggle-btn');
    };

    toggleChatButtons.forEach(function(button) {
      button.addEventListener('click', toggleButtonClick);
    });

    // Cleanup function
    return () => {
      toggleChatButtons.forEach(function(button) {
        button.removeEventListener('click', toggleButtonClick);
      });
    };
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="view-chat-convo-container no-chat-input">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            My Projects
          </h1>
          <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
            <button className="btn btn-primary">
              Create a New Project
            </button>
          </a>
        </div>
        <div className="projects-list">
          {[...Array(7)].map((_, index) => (
            <div className="projects-item" key={index}>
              <div className="projects-button">
                <div>
                  <button className="toggle-chat-options" inactive="true">
                    <i className="bi bi-three-dots"></i>
                  </button>
                </div>
              </div>
              <div className="projects-content">
                <div className="has-pin">
                  <a href="/project">Project title</a>
                  <ToggleButton storageKey={`toggleButton_${index}`} /> 
                </div>
                <p>
                  This is the project description. The button on the left toggles project options.
                </p>
                <div className="projects-item-links">
                  <div className="projects-item-link">
                    <button>
                      <i className="bi bi-box"></i>
                      <div className="projects-item-link-name">
                        21 Chats 
                      </div>
                    </button>
                  </div>
                  <div className="projects-item-link">
                    <button>
                      <i className="bi bi-box"></i>
                      <div className="projects-item-link-name">
                        36 Images 
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Projects