import React, { useEffect, useState } from 'react';
import Disclaimer from '../../components/ChatBox/Disclaimer';
import VoiceDictation from '../../components/ChatBox/VoiceDictation';
import ChatInput from '../../components/ChatBox/ChatInput';

function Search() {
  const [selectedOption, setSelectedOption] = useState("1"); // default selected option

  useEffect(() => {
    const searchView = document.querySelector('.view');
    if (searchView) {
      searchView.classList.add('view-dashboard');
    } 
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
    <div className="view-chat-convo-container">
      <div className="view-chat-convo-container-inner">
        <div className="heading">
          <h1>
            Search
          </h1>
          <h2>
            Ask Chatbot what you are looking for below
          </h2>
        </div>
      </div>
    </div>

    <div className="view-chat-box-container">
      <div className="view-chat-box">
        <VoiceDictation/>
        <select className="form-select search-select" value={selectedOption} onChange={handleChange}>
          <option value="1">All</option>
          <option value="2">Chats</option>
          <option value="3">Folders</option>
          <option value="4">Projects</option>
        </select>
        <ChatInput placeholder="Use @title for chat title searches" />
      </div>
      <Disclaimer/>
    </div>
    </>
  )
}

export default Search;