import React, { useEffect } from 'react';

function Project() {
  useEffect(() => {
    const chatView = document.querySelector('.main');
    if (chatView) {
      chatView.classList.add('has-project-header');
    }

    // Refactor this
    const hideAltOptions = document.querySelector('.chat-options');
    if (hideAltOptions) {
      hideAltOptions.classList.add('d-none');
    }

  }, []);

  return (
    <div className="project text-center">
      <div className="view-chat-convo-container no-chat-input">
        <div className="view-chat-convo-container-inner">
          <div className="breadcrumbs">
            <a href="/projects">My Projects</a> <i className="bi bi-chevron-right"></i> <a href="/project">Project title</a>
          </div>
          <div className="project-box border-b">
            <h2>
              Project Description
            </h2>
            <div className="project-description">
              <p>
                This is the project description. The button on the top right toggles project options. 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua.  
                <a href="/" className="ms-2 edit">Edit</a>
              </p>
            </div>
          </div>
          <div className="project-box">
            <h2>
              Project Items
            </h2>
            <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal4">
              Manage Items
            </a>
            <div className="row cards">
              <div className="col-md-3">
                <div className="card">
                  <i className="bi bi-chat-left-text-fill"></i>
                  <h3>
                   (21) Chats
                  </h3>
                  <a href="/project/chats">
                    View
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <i className="bi bi-image"></i>
                  <h3>
                   (36) Images
                  </h3>
                  <a href="/project/images">
                    View
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <i className="bi bi-bar-chart-fill"></i>
                  <h3>
                   (0) Graphs
                  </h3>
                  <a href="/project/graphs">
                    View
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <i className="bi bi-file-text-fill"></i>
                  <h3>
                   (0) Reports
                  </h3>
                  <a href="/project/reports">
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="project-box project-activity">
            <h2>
              Project Activity
            </h2>
            <table className="text-start">
              <thead>
                <tr>
                  <th className="text-center">
                    Type
                  </th>
                  <th>
                    Time
                  </th>
                  <th>
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-center">
                    <i className="bi bi-chat-left-text"></i>
                  </th>
                  <td>
                    Mar 3, 2024
                    <span>11:11am</span>
                  </td>
                  <td>
                    Added a chat to <span>Chats</span> 
                    <a href="/">Chat title here</a>
                  </td>
                </tr>
                <tr>
                  <th className="text-center">
                    <i className="bi bi-chat-left-text"></i>
                  </th>
                  <td>
                    Mar 3, 2024
                    <span>8:30am</span>
                  </td>
                  <td>
                    Removed a chat from <span>Chats</span> 
                    <a href="/">Chat title here</a>
                  </td>
                </tr>
                <tr>
                  <th className="text-center">
                    <i className="bi bi-image"></i>
                  </th>
                  <td>
                    Mar 1, 2024
                    <span>3:11pm</span>
                  </td>
                  <td>
                    Added an image to <span>Images</span>
                    <a href="/">Chat title here</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project