import React from 'react'

function UploadFile() {

  function toggleInputType() {
    var input = document.getElementById('textInput');
    var icon = document.getElementById('toggleIcon');

    if (input.type === 'text') {
      input.type = 'file';
      input.setAttribute('multiple', 'multiple'); // Allow multiple file selection
      icon.classList.remove('bi-file-earmark-arrow-up');
      icon.classList.add('bi-chat-left-dots');
      icon.setAttribute('title', 'Type Message');
    } else {
      input.type = 'text';
      input.removeAttribute('multiple'); // Remove multiple file selection
      icon.classList.remove('bi-chat-left-dots');
      icon.classList.add('bi-file-earmark-arrow-up');
      icon.setAttribute('title', 'Upload Media');
    }
  }

  return (
    <i id="toggleIcon" className="bi bi-file-earmark-arrow-up upload-icon" title="Upload Media" onClick={toggleInputType}></i>
  )
}

export default UploadFile