import React, { useEffect, useState } from 'react';

function ToggleButton({ storageKey }) {
  const [isToggled, setIsToggled] = useState(() => {
    // Initialize state from local storage if available, otherwise default to false
    const storedValue = localStorage.getItem(storageKey);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    // Save state to local storage whenever it changes
    localStorage.setItem(storageKey, JSON.stringify(isToggled));
  }, [isToggled, storageKey]);

  const handleClick = () => {
    setIsToggled(prevState => !prevState);
  };

  return (
    <button className={`toggle-pin ${isToggled ? 'toggled' : ''}`} onClick={handleClick}>
      <i className="bi bi-pin-fill"></i>
    </button>
  );
}

function ProjectChats() {
  useEffect(() => {
    const hideViewHeader = document.querySelector('.view');
    if (hideViewHeader) {
      hideViewHeader.classList.add('view-dashboard');
    }
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="view-chat-convo-container no-chat-input project-item-view">
      <div className="view-chat-convo-container-inner">
        <div className="breadcrumbs">
          <a href="/projects">My Projects</a> <i className="bi bi-chevron-right"></i> <a href="/project">Project title</a> <i className="bi bi-chevron-right"></i> <a href="/project/chats">Chats</a>
        </div>
        <div className="heading">
          <h1>(21) Chats</h1>
        </div>
        <div className="project-chats-list">
          {[...Array(7)].map((_, index) => (
            <div className="project-chats-item" key={index}>
              <div className="project-chats-content">
                <div className="has-pin">
                  <a href="/chat">Chat title</a>
                  <ToggleButton storageKey={`toggleButton_${index}`} /> 
                </div>
                <p>
                  This is a project chat preview. The user can pin items to the top. When the user clicks the chat title, there they will be able to have chat options.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectChats; 