import React from 'react'

function ProjectHeader() {
  
  function toggleSidebar(button) {
    // Toggle the options sidebar view
    var biIcons = document.querySelectorAll('.toggle-sidebar');
    var view = document.querySelector('.view');
    var viewSide = document.getElementById('viewSide');
    
    // Check if the clicked button already has the 'rotate' class
    var alreadyRotated = button.classList.contains('rotate');
    
    // Toggle the class 'rotate-45' on the clicked button
    button.classList.toggle('rotate');
    view.classList.toggle('p-right');
    
    // Toggle the class 'hide' on the .view-side element
    viewSide.classList.toggle('show-options');
    
    // Disable other buttons if the clicked button has 'rotate' class
    if (alreadyRotated) {
      biIcons.forEach(function(icon) {
        icon.disabled = false;
      });
    } else {
      biIcons.forEach(function(icon) {
        if (icon !== button) {
          icon.disabled = true;
        }
      });
    }
  }

  return (
    <div className="project-header show">
      <div className="view-title valign">
        <div className="view-title-container">
          <input type="text" placeholder="Project title" aria-label="Project title"/>
          <div className="view-title-rename-options">
            <i className="bi bi-check2"></i>
            <i className="bi bi-x"></i>
          </div>
        </div>
      </div>
      <div className="view-header-nav valign text-end">
        <ul>
          <li className="toggle-sidebar toggle-chat-options" onClick={(event) => toggleSidebar(event.target)}>
            <i className="bi bi-three-dots" title="View Project Options"></i>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ProjectHeader